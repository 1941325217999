import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const blogPost = ({ data }) => {
    console.log(data); // Check what's being passed to your template
    if (!data) {
        return <p>Loading...</p>; // Or handle the undefined case more gracefully
    }
    const { title, subTitle, intro, body, conclusion, illustration } = data.contentfulBlogPost

    // Options for rendering the rich text
  const options = {
    renderNode: {
      // Add other node renderers as needed
    },
    renderMark: {
      // Add renderers for marks if needed
    },
  }
  
  return (
    <article className="max-w-4xl mx-auto p-5">
      <header className="mb-8">
        <h1 className="text-4xl font-bold mb-4">{title}</h1>
        {subTitle && <h2 className="text-xl font-semibold text-gray-600 mb-4">{subTitle}</h2>}
      </header>
      <section className="mb-8 prose prose-lg">
        {intro && <div>{renderRichText(intro, options)}</div>}
      </section>
      <section className="mb-8 prose prose-lg">
        {body && <div>{renderRichText(body, options)}</div>}
      </section>
      {illustration && illustration.map(({ gatsbyImageData }, index) => (
        <GatsbyImage key={index} image={gatsbyImageData} alt={`Illustration ${index + 1}`} />
      ))}
      <footer>
        {conclusion && <div>{renderRichText(conclusion, options)}</div>}
      </footer>
    </article>
  )
}

export default blogPost

export const query = graphql`
  query ContentfulBlogPostByID($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      subTitle
      intro {
        raw
      }
      body {
        raw
      }
      illustration {
        file {
          url
        }
      }
      conclusion {
        raw
      }
    }
  }
`
